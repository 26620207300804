<template>
  <router-view />
  <DiscoveryModalOverlay />
</template>

<script setup>
import { onMounted, computed, provide } from 'vue';
import { loadTagLibrary } from '@vue-util/tagManager';
import useSiteMonGAMTags from '@modules/discovery/composables/useSitemonGAMTags';
import DiscoveryModalOverlay from '@components/DiscoveryModalOverlay';
import useEnvParams from '@composables/useEnvParams';
import { useError } from '@composables/useError';
import usePageData from '@composables/usePageData';

// Initialize GAM tags
const { initSiteMonGAMTags } = useSiteMonGAMTags();
initSiteMonGAMTags();

// Extract environment and page parameters
const { brand, tagMngrEnv } = useEnvParams();

// Provides exp Ids SSR
const pageData = computed(() => usePageData());
provide('experimentIds', pageData?.value?.meta?.context?.customerExperiment || []);

// Error handling
const { listenErrorCaptured } = useError();
listenErrorCaptured();

// Lifecycle hook for component mount
onMounted(async () => {
  loadTagLibrary(brand, tagMngrEnv, false);
  const { ExperimentationTags } = require('@vue-util/experimentation');
  await ExperimentationTags.fireAdobeTags();
});
</script>

<style lang="scss">
@import "~appSCSS";
</style>
