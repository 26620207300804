/**
 * Represents a device with a specific type.
 * @typedef {string} IDeviceType
 * @enum {'DESKTOP' | 'PHONE' | 'TABLET' | 'POS'}
 */

/**
 * Represents shopping mode types.
 * @typedef {string} IShoppingMode
 * @enum {'SITE' | 'WEDDING_REGISTRY'}
 */

/**
 * Configuration object representing page settings.
 * @typedef {Object} IPageParams
 * @property {IDeviceType} deviceType - The type of the device. Can be 'DESKTOP', 'PHONE', 'TABLET', 'POS'.
 * @property {boolean} isDomestic - Indicates if the user is from the US.
 * @property {boolean} isRegistry - Indicates if the shopping mode is 'WEDDING_REGISTRY'.
 * @property {string} regionCode - The region code of the user.
 * @property {IShoppingMode} shoppingMode - The shopping mode of the user. Can be 'SITE', 'WEDDING_REGISTRY'.
 * @property {string} currencyCode - The currency code relevant to the user.
 */

/**
 * Configuration object representing enviroment settings.
 * @typedef {Object} IEnvParams
 * @property {string} brand - The entry point from the environment variable (e.g., 'bcom', 'mcom').
 * @property {boolean} isBcom - True if the brand is 'bcom', otherwise false.
 * @property {boolean} isMcom - True if the brand is 'mcom', otherwise false.
 * @property {string} assetHost - The asset host URL from the environment variable.
 * @property {boolean} tagMngrEnabled - True if tag manager is enabled from the environment variable, otherwise false.
 * @property {string} tagMngrAccount - The tag manager account from the environment variable.
 * @property {string} tagMngrEnv - The tag manager environment from the environment variable.
 * @property {boolean} tealiumSyncEnabled - True if Tealium sync is enabled from the environment variable, otherwise false.
 */

module.exports = {};
