<template>
  <div class="error-message margin-bottom-m">
    <div class="error-message--card padding-m">
      <h2
        class="error-message--title"
        :class="titleColorClass"
      >
        Oops! We're experiencing a technical problem.
      </h2>
      <p class="error-message--message">
        We're hoping it's just a temporary glitch. Please close this window and try again.
        If you continue to have this problem, please call Customer Service at <a
          :href="`tel:${phoneNumbers[brand]}`"
          class="error-message--phone-link"
        >{{ phoneNumbers[brand] }}</a> for assistance.
      </p>
      <button
        class="error-message--button"
        type="button"
        tabindex="0"
        @click="back"
      >
        Continue Shopping
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { brand } from 'config';
import { useModal } from '@composables/useModal';
import { phoneNumbers } from '@utils/constants';

const { closeModal } = useModal();

const props = defineProps({
  id: {
    type: String,
    required: false,
  },
});

const router = useRouter();
const titleColorClass = `error-message--title-color-${brand}`;

const back = () => {
  closeModal(props.id);
  router.go(-1);
};

</script>

<style lang="scss">
$brandColors: (
  mcom: #c0090b,
  bcom: #000,
);
.error-message {
  display: flex;
  align-items: center;
  border-top: 0 #ccc;
  height: 400px;
  font-weight: 400;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;

  &--card {
    max-width: 38rem;
    margin: 0 auto;
    border: 1px solid #ccc;
    box-shadow: 1px 2px #ccc;
  }

  &--title {
    font-size: 1.4rem;
    padding-bottom: 1rem;

    &-color-mcom {
      color: #c0090b;
    }
    &-color-bcom {
      color: #000000;
    }
  }

  &--message {
    font-size: 1.1rem;
  }

  &--button {
    text-decoration: underline;
    cursor: pointer;
    padding-top: 1rem;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 38rem) {
  .error-message {
    border: none;
  }
}
</style>
