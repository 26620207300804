import { useStore } from 'vuex';
import { IPageParams } from '@types/defenitions'; // eslint-disable-line no-unused-vars

/**
 * Use page parameters from the Server.
 *
 * @returns {IPageParams} - static object with page params from the server.
 */
export default function usePageData() {
  return useStore()?.getters?.getPage;
}
