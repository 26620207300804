import {
  shallowRef, computed, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import { SET_MODAL_OVERLAY_VISIBILITY } from '@atomic-ui/modalOverlay';

const modals = shallowRef([]);

export function useModal() {
  const store = useStore();

  function showModal(modalObj) {
    modals.value = [...modals.value, modalObj];

    nextTick(() => {
      store.commit(`modalOverlayStore/${SET_MODAL_OVERLAY_VISIBILITY}`, modalObj.id);
    });
  }

  function closeModal(id) {
    const filtered = modals.value.filter((_modal) => _modal.id !== id);
    modals.value = [...filtered];
  }

  const modal = computed(() => modals.value[0]);

  return {
    showModal,
    closeModal,
    modal,
    modals,
    isModalAvailable: computed(() => !!modal.value),
  };
}

export default useModal;
