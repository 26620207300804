/* istanbul ignore file */
/**
 * Example using code splitting and componentization. The run-time imports will cause bundle split
 * points for webpack, and as a result, load chunks on-demand (when navigating between routes).
 *
 * @see https://webpack.js.org/guides/code-splitting/#dynamic-imports
 */

import redirectToHome from '@utils/redirectToHome';
import Home from './views/Home';
import PageNotFound from './views/PageNotFound';

export default () => [
  {
    name: 'home',
    path: '/',
    component: Home,
  },
  {
    name: 'search',
    path: '/shop/search/:pathMatch(.*)*',
    component: () => import('./views/Search'),
    props: (route) => ({
      keyword: route.query.keyword,
      pageType: 'search',
    }),
    beforeEnter: (to) => {
      redirectToHome(to.query.keyword);
    },
  },
  {
    name: 'registrySearch',
    path: '/shop/registry/wedding/search/:pathMatch(.*)*',
    component: () => import('./views/Search'),
    props: (route) => ({
      keyword: route.query.keyword,
      pageType: 'registrySearch',
    }),
  },
  {
    name: 'featured',
    path: '/shop/featured/:featuredName/:pathMatch(.*)*',
    component: () => import('./views/Search'),
    props: (route) => ({
      ...route.params,
      featuredName: route.params.featuredName,
      pageType: 'featured',
    }),
  },
  {
    name: 'buy',
    path: '/buy/:featuredName/:pathMatch(.*)*',
    component: () => import('./views/Search'),
    props: (route) => ({
      ...route.params,
      featuredName: route.params.featuredName,
      pageType: 'buy',
    }),
  },
  {
    name: 'featuredRedirect',
    path: '/shop/featured/',
    component: () => import('./views/Search'),
    beforeEnter: () => {
      redirectToHome();
    },
  },
  {
    name: 'shopPlp',
    path: '/shop/:pathMatch(.*)*',
    component: () => import('./views/Browse'),
    props: (route) => ({
      id: route.query.id,
    }),
  },
  {
    // Add a path-match route for 404 Page Not Found
    name: 'notfound',
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
  },
];
