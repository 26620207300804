import { deviceTypes } from '@utils/constants';
import useMetaContext from './useMetaContext';

export default function useDevice() {
  const context = useMetaContext();
  const isPhone = context.value.deviceType === deviceTypes.PHONE;
  const isTablet = context.value.deviceType === deviceTypes.TABLET;
  const isDesktop = context.value.deviceType === deviceTypes.DESKTOP;

  return {
    isPhone, isTablet, isDesktop, deviceType: context.value?.deviceType,
  };
}
