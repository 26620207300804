<template>
  <div class="intro">
    <h1>Welcome to Vue 3 app scaffold</h1>
    <div class="grid-x grid-margin-x grid-margin-y align-middle padding-m">
      <a
        rel="noopener noreferrer"
        tag="button"
        class="button secondary cell small-4 padding-xs"
        @click="openInNewTab({ name: 'search', query: { keyword: 'jeans' } })"
      >
        Search page
      </a>
      <a
        tag="button"
        target="_blank"
        class="button secondary cell small-4 padding-xs"
        @click="openInNewTab(browseUrl)"
      >
        Browse page
      </a>
      <a
        tag="button"
        class="button secondary cell small-4 padding-xs"
        @click="openInNewTab({ name: 'featured', params: { featuredName: 'dress' } })"
      >
        Featured page
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { brand } from 'config';

const router = useRouter();

function openInNewTab(url) {
  const routeData = router.resolve(url);
  window.open(routeData.href, '_blank');
}

const browseUrl = computed(() => {
  if (brand === 'bcom') {
    return {
      name: 'browse',
      params: {
        categoryName: 'handbags',
        categoryProduct: 'womens-handbags-purses',
      },
      query: {
        id: '17316',
      },
    };
  }
  return {
    name: 'browse',
    params: {
      categoryName: 'shoes',
      categoryProduct: 'all-womens-shoes',
    },
    query: {
      id: '56233',
    },
  };
});

</script>

<style lang="scss" scoped>
.intro {
  margin: 20%;
  text-align: center;
  padding: 2em;
  border-radius: 10px;
  border: 2px solid #42b883;
}

button {
  margin: 0 20px;
}
</style>
