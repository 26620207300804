import { ref, readonly } from 'vue';
import ErrorModal from '@components/ErrorModal';
import { useModal } from '@composables/useModal';
import generateRandomString from '@utils/generateRandomString';

let modal;
const errors = ref([]);

function clearError(id) {
  errors.value = [...errors.value.filter((err) => err.id === id)];
}

function showModalError(err) {
  errors.value = [...errors.value, err];
  // TODO use actual logger
  console.error(err); // eslint-disable-line no-console
  modal.showModal({
    id: `global-error-dialog-${generateRandomString()}`,
    title: 'That wasn\'t supposed to happen...',
    body: ErrorModal,
    width: '38rem',
  });
}

function listenErrorCaptured() {
  // eslint-disable-next-line no-unused-vars
  // onErrorCaptured((err) => false);
  // TODO use actual logger
  // console.error(err); // commented due to reference error on brand index pages
  // TODO remove if app.config.errorHandler will be implemented
}

export function useError() {
  modal = useModal();

  return {
    listenErrorCaptured,
    clearError,
    showModalError,
    errors: readonly(errors),
  };
}

export default useError;
